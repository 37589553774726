import YouTubeDislikeViewer from "./YoutubeDislikeViewer";
function App() {
  return (
    <div className="inner-wrapper">
      <YouTubeDislikeViewer />
    </div>
    
  );
}

export default App;
