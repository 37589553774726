import React, { useState, useEffect } from 'react';
import axios from 'axios';

const YouTubeDislikeViewer = () => {
  const [url, setUrl] = useState('');
  const [data, setData] = useState(null);
  const [error, setError] = useState('');

  const isValidYouTubeUrl = (url) => {
    const regex = /^(https:\/\/www\.youtube\.com\/watch\?v=|https:\/\/youtu\.be\/)([a-zA-Z0-9_-]{11})(.*)?$/;
    return regex.test(url);
  };

  const getVideoIdFromUrl = (url) => {
    const regex = /(?:v=|\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const fetchData = async (videoId) => {
    try {
      const response = await axios.get(`https://returnyoutubedislikeapi.com/votes?videoId=${videoId}`);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to fetch data. Please try again.');
    }
  };

  const updateUrl = (videoId) => {
    const newUrl = `https://youtu.be/${videoId}`;
    const params = new URLSearchParams(window.location.search);
    params.set('url', newUrl);
    window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
  };

  const handleButtonClick = () => {
    if (isValidYouTubeUrl(url)) {
      const videoId = getVideoIdFromUrl(url);
      if (videoId) {
        fetchData(videoId);
        updateUrl(videoId);
      } else {
        setError('Invalid YouTube URL.');
      }
    } else {
      setError('Invalid YouTube URL.');
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      alert('URL copied to clipboard!');
    });
  };

  const handleShareLink = () => {
    navigator.share({
      title: 'YouTube Dislike Viewer',
      url: window.location.href,
    }).catch((error) => console.log('Error sharing:', error));
  };

  const handleShareTwitter = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?url=${window.location.href}`;
    window.open(twitterUrl, '_blank');
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const videoUrl = params.get('url');
    if (videoUrl) {
      setUrl(videoUrl);
      if (isValidYouTubeUrl(videoUrl)) {
        const videoId = getVideoIdFromUrl(videoUrl);
        if (videoId) {
          fetchData(videoId);
        } else {
          setError('Invalid YouTube URL.');
        }
      } else {
        setError('Invalid YouTube URL.');
      }
    }
  }, []);

  return (
    <div className="tool-component">
      <div className="input-field">
        <input
          type="text"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="Enter YouTube URL"
          className="input"
        />
        <button onClick={handleButtonClick} className="button">View Dislikes</button>
      </div>
      {error && <p className="error">{error}</p>}
      {data && (
        <div className="video-container">
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${getVideoIdFromUrl(url)}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <div>
            <p>Views: {data.viewCount}</p>
            <p>Likes: {data.likes}</p>
            <p>Dislikes: {data.dislikes}</p>
          </div>
          <div className="button-container">
            <button onClick={handleCopy} className="small-button">Copy</button>
            <button onClick={handleShareLink} className="small-button">Share Link</button>
            <button onClick={handleShareTwitter} className="small-button">Share on Twitter</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default YouTubeDislikeViewer;
